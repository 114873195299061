<template>
    <b-container>
        <b-card style="margin-top: 40%;">
            <b-card-title>歡迎使用NubeTech Hub</b-card-title>
            <b-card-sub-title>登入你的帳號</b-card-sub-title>

            <b-form class="mt-3" @submit.stop.prevent="onSubmit">
                <b-row>
                    <b-col md="12" lg="6">
                        <b-form-group>
                            <b-form-input v-model="code" placeholder="請輸入商戶代號" />
                        </b-form-group>
                    </b-col>
                    <b-col md="12" lg="6">
                        <b-form-group>
                            <b-form-input v-model="password" type="password" placeholder="請輸入密碼" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-form-group>
                    <b-form-checkbox v-model="isRemember">記住我</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                    <b-button type="submit" variant="primary" block>登入</b-button>
                </b-form-group>
            </b-form>
        </b-card>
    </b-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            code: '',
            password: '',
            isRemember: false,
        }
    },

    created() {
        this.code = localStorage.code ? localStorage.code : ''
        this.password = localStorage.password ? localStorage.password : ''
        this.isRemember = this.password ? true : false
    },

    computed: {
        ...mapState({
            merchants: state => state.merchants,
        })
    },

    methods: {
        onSubmit() {
            if (this.code == 'nubetech' && this.password == '2O2@o5!!') {
                localStorage.authType = 'admin'
                localStorage.merchantName = 'NubeTech創造神'
                console.log('authType: admin')
            } else {
                const merchant = this.merchants.find(merchant => merchant.code == this.code)
                if (!merchant) return

                switch (this.code) {
                    case 'nube-burger':
                        if (this.password != '2O2@o5!!') return
                        break
                    case 'tai-gor-kitchen':
                        if (this.password != '8SaNJTuuC8') return
                        break
                    case 'cluck-cluck':
                        if (this.password != 'F1NI9KSWG9') return
                        break
                    case 'golden-chicken':
                        if (this.password != 'NdhcFi51cH') return
                        break
                    case 'yiu-fung-store':
                        if (this.password != '9kDBaXbQ8N') return
                        break
                    case 'samadhi':
                        if (this.password != '88zky4mi0i') return
                        break
                    default:
                        return
                }

                localStorage.authType = 'owner'
                localStorage.merchantId = merchant.id
                localStorage.merchantName = merchant.name
                console.log('authType: owner')
                console.log('merchantId: ' + merchant.id)
            }

            localStorage.isAuth = true
            localStorage.code = this.code
            this.isRemember ? localStorage.password = this.password : localStorage.removeItem('password')
            
            return this.$router.push(localStorage.authType == 'admin' ? '/godDashboard' : '/dashboard')
        }
    }
}
</script>